import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { Sidebar, Snackbar } from '@agco-onedigital/ecosystem-lib-web-components';
import {
  EAuthRoutes,
  useSidebarProps,
  ISidebarProps,
  FSEAuthRoutes,
  commonMessages,
} from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { useAppDispatch } from '../../store';
import { setActiveAccount, useUserAccount } from '../../store/UserAccount';
import { saveUserThunk, useUser } from '../../store/User';
import { useLaunchDarkly } from '../../store/LaunchDarkly';
import { resetMachineList } from '../../store/MachineList';
import { handleLogoutRedirect } from '../../utils/msalConfiguration/utils';
import { useDtrumUserSetup } from '../../utils/dtrumUserSetup';

import { getMachineManagementEnabled, useServiceInformationLinks } from './utils';

const ROUTE_CHECK_PATHS = [EAuthRoutes.SERVICE_AND_INFORMATION, EAuthRoutes.FLEET_OVERVIEW];
const messages = commonMessages.business;

const SidebarContainer = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name } = useTheme();
  const launchDarkly = useLaunchDarkly();
  const { activeAccountId } = useUserAccount();
  const { data } = useUser();
  const externalLinks = useServiceInformationLinks();

  useDtrumUserSetup();

  const accounts = useMemo(
    () =>
      (data?.accounts || []).map(account => ({
        id: account.account.id,
        label: account.account.name ?? '',
      })),
    [data?.accounts],
  );

  const handleSelectAccount = useCallback(
    (newAccountId: string) => {
      const isSameAccountSelected = activeAccountId === newAccountId;
      const shouldNavigate =
        ROUTE_CHECK_PATHS.some(route => window.location.pathname.includes(route)) && !isSameAccountSelected;

      if (shouldNavigate) {
        navigate(FSEAuthRoutes.FLEET_OVERVIEW);
      }

      if (!isSameAccountSelected) {
        const selectedAccount = accounts.find(account => account.id === newAccountId);

        dispatch(setActiveAccount(newAccountId));
        dispatch(saveUserThunk({ lastUsedAccountId: newAccountId }));
        dispatch(resetMachineList());

        Snackbar.showSnack({
          title: formatMessage(messages.businessChangedSuccessSnackMessageTitle),
          message: formatMessage(messages.businessChangedSuccessSnackMessageDescription, {
            newBusinessName: selectedAccount?.label ?? '',
          }),
          type: 'success',
          duration: 5000,
        });
      }
    },
    [accounts, activeAccountId, dispatch, formatMessage, navigate],
  );

  const handleLogOut = () => {
    dispatch(setActiveAccount(''));
    handleLogoutRedirect(launchDarkly);
  };

  const sidebarProps: ISidebarProps = {
    isMachineManagementEnabled: getMachineManagementEnabled(launchDarkly, name),
    isDataAndPrivacyPageEnabled: launchDarkly['data-privacy-page-visibility'] ?? false,
    isEcomEnabled: launchDarkly['ecom-enabled'] ?? false,
    ecomUrl: launchDarkly['config-web-app']?.ecosystem?.externalLinks?.['part-shop'] ?? '',
    isCpqEnabled: launchDarkly['cpq-enabled'] ?? false,
    isManualsEnabled: launchDarkly['sidebar-manuals-enabled'] ?? false,
    isWarrantyEnabled: launchDarkly['sidebar-warranty-enabled'] ?? false,
    isPSPEnabled: launchDarkly['sidebar-psp-enabled'] ?? false,
    isDTCEnabled: launchDarkly['sidebar-dtc-enabled'] ?? false,
    isServiceAndInformationBasePathEnabled: launchDarkly['sidebar-service-information-link-enabled'] ?? false,
    accountItems: accounts,
    activeAccountId,
    onAccountSelect: handleSelectAccount,
    handleLogOut,
    user: {
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
    },
    externalLinks,
  };

  const { topItems, bottomItems, userData } = useSidebarProps(sidebarProps);

  return <Sidebar topItems={topItems} bottomItems={bottomItems} logoPath={EAuthRoutes.DASHBOARD} user={userData} />;
};

export default SidebarContainer;
